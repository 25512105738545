import { ref, computed } from "vue";
import useDateTimeFormatter from "@/composables/formatDateTime.js";

export default function useDetailEdit() {
  const cModeEnum = { addMaster: "addMaster", addDetail: "addDetail", editData: "editData", editTL: "editTL", readOnly: "isReadOnly" };
  const { formatDateIsoD, formatDateUtcD, formatDateUtcDT } = useDateTimeFormatter();

  const mode = ref(cModeEnum.readOnly);

  const details = ref([]);
  const hasDetails = computed(() => details.value.length > 0);

  const selectedIndex = ref(undefined);
  const selectedData = ref({});

  const isReadOnly = computed(() => mode.value == cModeEnum.readOnly);
  const isAddingMode = computed(() => mode.value == cModeEnum.addMaster || mode.value == cModeEnum.addDetail);
  const isAddingMasterMode = computed(() => mode.value == cModeEnum.addMaster);
  const isAddingDetailMode = computed(() => mode.value == cModeEnum.addDetail);
  const isEditDataMode = computed(() => mode.value == cModeEnum.editData);
  const isEditTLMode = computed(() => mode.value == cModeEnum.editTL);

  const allowEdit = computed(() => mode.value == cModeEnum.addMaster || mode.value == cModeEnum.addDetail || mode.value == cModeEnum.editData);
  const allowEditTL = computed(() => mode.value == cModeEnum.addMaster || mode.value == cModeEnum.addDetail || mode.value == cModeEnum.editTL);

  const modDateFormatted = computed(() => formatDateUtcDT(selectedData.value.modDat));

  function setMode(id, isAdding) {
    if (isAdding) {
      if (!id || id == "0") {
        mode.value = cModeEnum.addMaster;
      } else {
        mode.value = cModeEnum.addDetail;
      }
    } else {
      mode.value = cModeEnum.readOnly;
    }
  }

  function setReadonlyMode() {
    mode.value = cModeEnum.readOnly;
  }

  function setDetails(data) {
    //console.log("detailEdit, setDetails: ", data);
    details.value = [...data];
    if (details.value.length > 0) {
      //selectNewIndex(details.value.length - 1);
      return details.value.length - 1;
    }
  }

  function updateSelectedDetail() {
    details.value[selectedIndex.value] = Object.assign({}, selectedData.value);
  }

  function resetSelectedDetail() {
    selectNewIndex(selectedIndex.value);
  }

  function selectNewIndex(index) {
    //deep copy
    selectedIndex.value = index;
    selectedData.value = JSON.parse(JSON.stringify(details.value[index]));
    console.log("detailEdit, selectNewIndex, selectedData.value: ", index, selectedData.value);
  }

  // function tabText(index) {
  //   const item = details.value[index];
  //   let txt = formatDateUtcD(item.valFrom);
  //   if (formatDateUtcD(details.value[index + 1]?.valFrom) != formatDateUtcD(item.valTill) && item.valTill != null) {
  //     txt = `${txt} - ${formatDateUtcD(item.valTill)}`;
  //   }
  //   if (item.modDescr && item.modDescr.length > 0) {
  //     txt = `${txt} : ${item.modDescr}`;
  //   }
  //   return txt;
  // }

  function detailTabs() {
    //console.log("detailEdit, detailTabs: ");
    const tabText = details.value.map((item, index) => {
      let txt = formatDateUtcD(item.valFrom);
      if (formatDateUtcD(details.value[index + 1]?.valFrom) != formatDateUtcD(item.valTill) && item.valTill != null) {
        txt = `${txt} - ${formatDateUtcD(item.valTill)}`;
      }
      if (item.modDescr && item.modDescr.length > 0) {
        txt = `${txt} : ${item.modDescr}`;
      }
      //      console.log("detailEdit, detailTabs: ", txt);
      return txt;
    });
    return tabText;
  }

  function checkDateValid() {
    return { on: ["input"] };
  }

  function setEditMode() {
    mode.value = cModeEnum.editData;
    return false;
  }

  function setEditTLMode() {
    mode.value = cModeEnum.editTL;
    return false;
  }

  async function addNewDetailMode() {
    //console.log("+++ addNewDetail, selectedData, selectedIndex: ", selectedData.value, selectedIndex.value);
    selectedData.value.valFrom = undefined;
    selectedData.value.valTill = undefined;

    let dateToday = new Date();
    let valFrom = new Date(details.value[selectedIndex.value].valFrom).valueOf();
    let valTill = details.value[selectedIndex.value].valTill ? new Date(details.value[selectedIndex.value].valTill).valueOf() : null;

    if (details.value.length == selectedIndex.value + 1) {
      //console.log("+++ laatste of enige index.   valTill: ", valTill, formatDateIsoD(dateToday));
      // Dit is de laatste of enige index
      if ((valTill == null || valTill < dateToday) && valFrom < dateToday) {
        //console.log("+++ datum-tot is leeg of kleiner dan vandaag en validFrom is kleiner dan vandaag");
        // De validFrom is kleiner dan vandaag. De nieuwe validFrom wordt gevuld met vandaag
        selectedData.value.valFrom = formatDateIsoD(dateToday);
        selectedData.value.valTill = undefined;
      } // Else. De validTill is gevuld en groter/gelijk dan vandaag of de validFrom is groter/gelijk dan vandaag. Data worden leeg gelaten.
    } else {
      let nextValFrom = new Date(details.value[selectedIndex.value + 1].valFrom).valueOf();
      //console.log("+++ meerdere indexen maar niet de laatste, valTill: ", valTill, nextValFrom, valTill - nextValFrom);
      // Er zijn meerdere indexen maar dit is niet de laatste
      if (valTill != nextValFrom) {
        //console.log("+++ meerdere indexen maar niet de laatste, valTill != volgende validFrom: ", valTill, nextValFrom);
        // De validTill is ongelijk aan de volgende validFrom. Er zit een tussenrecord tussen de huidige en de volgende index. Het gat wordt opgevuld.
        selectedData.value.valFrom = formatDateIsoD(valTill);
        selectedData.value.valTill = formatDateIsoD(nextValFrom);
      } // Else. De validTill sluit aan op de volgende validFrom. Data worden leeg gelaten.
    }

    selectedData.value.modDescr = undefined;
    mode.value = cModeEnum.addDetail;
  }

  return {
    setMode,
    setReadonlyMode,
    setEditMode,
    setEditTLMode,
    allowEdit,
    allowEditTL,
    setDetails,
    hasDetails,
    selectNewIndex,
    selectedData,
    updateSelectedDetail,
    resetSelectedDetail,
    isReadOnly,
    isAddingMode,
    isAddingMasterMode,
    isAddingDetailMode,
    isEditDataMode,
    isEditTLMode,
    addNewDetailMode,
    detailTabs,
    modDateFormatted,
    checkDateValid,
  };
}
